<!-- 解决方案 -->
<template>
  <div class="wrapper pageBox">
    <!-- banner -->
    <solutionBanner id="solutionBanner" ref="solutionBanner" :from="'zwfw'" />
    <!-- 二级导航 -->
    <navBar ref="navBar" :from="'Scheme'" />
    <!-- 行业分析 -->
    <div id="hyfx_div" class="fenxi-box">
      <h1 class="h1-title">行业分析</h1>
      <div class="fenxi conBox flex-between flex-align">
        <div class="imgs">
          <img class="img" src="@/assets/solution/cyyq/fenxi-zw.png">
          <!-- <div class="color-block" /> -->
        </div>
        <div>
          <div class="text" style="margin-top:15px">
            <span class="text-title">服务不精准：</span>
            轻资产、初创型、成长型企业越来越多，而传统金融模式和手段难以精准匹配这类企业需求。助企政策虽多，有的企业却不知道该选择“哪一款”。
          </div>
          <div class="text" style="margin-top:15px">
            <span class="text-title">需求多维化：</span>
            站在传统产业转型升级、新兴产业培育壮大的关口，广大市场经营主体对政务服务的各类需求也在转变。基本政务服务需求外，它们对人才、金融、科创等社会服务和市场服务更为渴求。
          </div>
          <div class="text" style="margin-top:15px">
            <span class="text-title">职能分割弊端及行业限制：</span>
            传统的行政服务中心，服务大厅设置隔断区，企业办事多头跑,产业链上下游缺少协调联动机制，信息差阻碍产业升级。企业急需一场改革，突破既有部门间物理空间上的隔离以及行业隐形的限制。
          </div>
        </div>
      </div>
    </div>
    <!-- 解决方案能力全景 -->
    <div id="faqj_div" class="conBox nlfa">
      <h1 class="h1-title">解决方案能力全景</h1>
      <img class="img" src="@/assets/solution/cyyq/nlfa-zw.png">
      <img class="img1" src="@/assets/solution/cyyq/jjfabg-zw.png">
    </div>
    <!-- 平台典型功能介绍 -->
    <carouselBanner id="ptgn_div" ref="carouselBanner" :from="'zwfw'" />
    <!-- 适用场景 -->
    <div id="sycj_div" class="sycj-div">
      <h1 class="h1-title">适用场景</h1>
      <sycj ref="sycj" class="conBox" :from="'zwfw'" />
    </div>
    <!-- 数字嘉园 美好生活 -->
    <footers ref="footers" />
  </div>
</template>

<script>
import solutionBanner from './components/solutionBanner.vue';
import sycj from './components/sycj.vue';
import footers from './components/footer.vue';
import carouselBanner from './components/carouselBanner.vue';
import navBar from './components/navBar.vue';
export default {
  name: 'Solution',
  components: { solutionBanner, sycj, footers, carouselBanner, navBar },
  data() {
    return {
    };
  },
  watch: {
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>

.flex-start {
  display: flex;
  justify-content: start;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-align {
  align-items: center;
}
.conBox {
  width: 1200px;
  margin: 0 auto;
}
.fenxi-box {
  padding-bottom: 80px;
  background: #F9FBFF;
  .fenxi {
    .imgs,.img{
      position: relative;
      width: 594px;
      height: 380px;
      .color-block {
        position: absolute;
        bottom: -24px;
        left: -32px;
        width: 73px;
        height: 73px;
        background: #30a5ff;
      }
    }
    .text {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #666666;
      line-height: 30px;
      padding-left: 50px;
      text-align: justify;
      .text-title {
        color: #30a5ff;
        font-weight: bold;
      }
    }
  }
}
.nlfa {
  position: relative;
  text-align: center;
  margin-bottom: 80px;
  .img {
    width: 1229px;
    height: 660px;
    margin: auto;
  }
  .img1 {
    position: absolute;
    width: 603px;
    height: 506px;
    top: 250px;
    right: -181px;
  }
}
</style>

